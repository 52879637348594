<template>
  <div class="sharepage"  v-title data-title="分享">
    <div class="h80"></div>
    <div class="sharebox" ref="posterHtml" v-show="isshare">
      <div class="share-title">{{ sharetitle }}</div>
      <!-- 二维码 -->
      <div class="qrcode">
        <div id="qrcodeImg">
          <img src="" alt="" />
        </div>
      </div>
      <img :src="posterHtmlBg" class="bgimg" alt="" />
    </div>
    <div class="sharebox" v-show="!isshare">
      <img ref="shareimg" src="" alt="" />
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import $ from "jquery";
export default {
  data() {
    return {
      isshare: true,
      posterContent: "", // 文案内容
      posterHtmlBg: require("./loginimg2.png"), // 背景图
      posterImg: "", // 最终生成的海报图片
      sharetitle: "",
    };
  },
  created() {
    this.sharetitle = this.$route.query.title;
  },
  mounted() {
    let rout = this.$route.query;
    if (rout.tourl === "/videoDetail") {
      this.posterContent = `http://s.yi-lian.net/#${rout.tourl}?id=${rout.id}`;
    }else if(rout.tourl === "/liveRoom"){
      console.log("content:",rout.content);
      this.posterContent = rout.content;
    }
    this.createQrcode(this.posterContent);
    this.createPoster();
  },
  methods: {
    createQrcode(text) {
      // 生成二维码
      const qrcodeImgEl = document.getElementById("qrcodeImg");
      qrcodeImgEl.innerHTML = "";
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 90,
        height: 90,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      qrcode.makeCode(text);
      console.log("createQrcode");
    },
    createPoster() {
      $("#sharetitle").text(this.$route.query.title);
      // 生成海报
      html2canvas(this.$refs.posterHtml, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,
      })
        .then((canvas) => {
          let imagurl = canvas.toDataURL("image/jpeg"); //下载链接
          this.$refs.shareimg.setAttribute("src", imagurl);
        })
        .catch((err) => {
          console.log("生成失败：", err);
        });
      this.isshare = false;
      console.log("createPoster");
    },
  },
};
</script>

<style lang="less" scoped>
.sharepage {
  width: 90vw;
  height: 70vh;
  margin: 0 auto;
  .sharebox {
    width: 90vw;
    height: 80vh;
    margin: 0 auto;
    background-size: 100% 100%;
    position: relative;
    .bgimg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .share-title {
      color: white;
      font-size: 1rem;
      position: absolute;
      bottom: 7rem;
      width: 100%;
      display: inline-block;
      text-align: center;
      font-weight: 700;
    }
    .qrcode {
      position: absolute;
      bottom: 10px;
      left: 50%;
      margin-left: -45px;
      border: 3px solid white;
      width: 96px;
      height: 96px;
      background-color: #fff;
    }
  }
}
</style>